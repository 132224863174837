import { TimeType } from "@twire/redux/tournaments/types";
import { mergeClasses, t } from "@twire/utility/helpers";
import TournamentNav from "../TournamentsNav";
import Articles from "@twire/common/Articles";
import TournamentsTemplate from "../../TournamentsTemplate";
import TournamentsFilter from "../../TournamentsFilter";
import { AdsContext } from "../../context";

import classes from "./TournamentsView.module.scss";
import FantasyBanner from "@twire/common/FantasyBanner";
import FantasyTournaments from "@twire/common/FantasyTournaments";

const TournamentsView: React.FC<{ type: TimeType; scrims?: boolean }> = ({
  type,
  scrims,
}) => (
  <div className={mergeClasses(classes.container, "appear")}>
    <div className={classes.left}>
      {!scrims && <TournamentNav />}
      {/* <AdsContext.Consumer>
        {({ showAds }) => (
          showAds && <div id="v-twire-gg" className="mt-3"></div>
        )}
      </AdsContext.Consumer> */}
      {!scrims ? (
        <div className="mt-6">
          {type === "ongoing" && (
            <>
              <div className="flex text-twire-light font-bold items-center">
                <span className="mr-3 text-xl">{t("words.filters")}</span>
                <TournamentsFilter type="ongoing" />
              </div>
              <TournamentsTemplate type="ongoing" infinite />
            </>
          )}
          {type === "past" && (
            <>
              <div className="flex text-twire-light font-bold items-center">
                <span className="mr-3 text-xl">{t("words.filters")}</span>
                <TournamentsFilter type="past" />
              </div>
              <TournamentsTemplate type="past" infinite />
            </>
          )}
          {type === "upcoming" && (
            <>
              <div className="flex text-twire-light font-bold items-center">
                <span className="mr-3 text-xl">{t("words.filters")}</span>
                <TournamentsFilter type="upcoming" />
              </div>
              <TournamentsTemplate type="upcoming" infinite />
            </>
          )}
        </div>
      ) : (
        <div className="mt-0">
          <div className="flex text-twire-light font-bold items-center">
            <span className="mr-3 text-xl">{t("words.filters")}</span>
            <TournamentsFilter type="ongoing" scrims />
          </div>
          <TournamentsTemplate type="upcoming" infinite scrims />
        </div>
      )}
    </div>
    <div className={classes.right}>
      <div className="mb-4">
        <FantasyBanner />
      </div> 
      <FantasyTournaments />
      <Articles config={{ visibleCount: 3 }} />
    </div>
  </div>
);

export default TournamentsView;
