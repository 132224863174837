import { all, takeLatest, call, put, select } from "redux-saga/effects";
import Auth from "@aws-amplify/auth";

import * as actionTypes from "./actionTypes";
import {
  signIn,
  signUp,
  getSignInUser,
  forgotPassword,
  resetPassword,
  verifyEmail,
  resendVerifyEmail,
} from "./actions";
import { privateQuery } from "../../graphql/requestHelper";
import { userSubscription } from "../../graphql/private/queries";
import {loadAdScript, unloadAdScript} from "../../utility/helpers";
import { RootReducer } from "../types";
import { CACHE_TIME } from "../../graphql/cache";

function* signInAction(action: ReturnType<typeof signIn>) {
  yield put({ type: actionTypes.AUTH_SIGN_IN_REQUESTED });
  try {
    // Validate recaptcha token
    // const recaptchaResponse: boolean | string = yield call([Recaptcha, 'verify'], action.payload.recaptchaToken);
    // if (typeof recaptchaResponse === 'string') {
    //   throw new Error(
    //       `Recaptcha verification failed, try different browser or contact support. Error: ${recaptchaResponse}`,
    //   );
    // }
    // console.log('Recaptcha verification success');
    const user: Generator = yield call([Auth, "signIn"], action.payload);
    yield put({ type: actionTypes.AUTH_SIGN_IN_SUCCESS, user });
    yield put({ type: actionTypes.AUTH_GET_USER_SUBSCRIPTION });
  } catch (error) {
    yield put({ type: actionTypes.AUTH_SIGN_IN_FAILED, error });
  }
}

function* signOutAction() {
  yield put({ type: actionTypes.AUTH_SIGN_OUT_REQUESTED });
  try {
    yield call([Auth, "signOut"]);
    yield put({ type: actionTypes.AUTH_SIGN_OUT_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.AUTH_SIGN_OUT_FAILED, error });
  }
}

function* signUpAction(action: ReturnType<typeof signUp>) {
  yield put({ type: actionTypes.AUTH_SIGN_UP_REQUESTED });
  try {
    // Validate recaptcha token
    // const recaptchaResponse: boolean | string = yield call([Recaptcha, 'verify'], action.payload.recaptchaToken);
    // if (typeof recaptchaResponse === 'string') {
    //   throw new Error(
    //       `Recaptcha verification failed, try different browser or contact support. Error: ${recaptchaResponse}`,
    //   );
    // }
    // console.log('Recaptcha verification success');
    yield call([Auth, "signUp"], {
      username: action.payload.username,
      password: action.payload.password,
      attributes: {
        email: action.payload.email,
        "custom:newsletter": String(action.payload.newsletter),
      },
    });
    yield put({ type: actionTypes.AUTH_SIGN_UP_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.AUTH_SIGN_UP_FAILED, error });
  }
}

function* getSignInUserAction(action: ReturnType<typeof getSignInUser>) {
  yield put({ type: actionTypes.AUTH_GET_SIGN_IN_USER_REQUESTED });
  try {
    const user: Generator = yield call(
      [Auth, "currentAuthenticatedUser"],
      action.payload
    );
    yield put({ type: actionTypes.AUTH_GET_SIGN_IN_USER_SUCCESS, user });
    yield put({ type: actionTypes.AUTH_GET_USER_SUBSCRIPTION });
  } catch (error) {
    yield put({ type: actionTypes.AUTH_GET_SIGN_IN_USER_FAILED, error });
    yield put({ type: actionTypes.AUTH_GET_USER_SUBSCRIPTION });
  }
}

function* forgotPasswordAction(action: ReturnType<typeof forgotPassword>) {
  yield put({ type: actionTypes.AUTH_FORGOT_PASSWORD_REQUESTED });
  try {
    yield call([Auth, "forgotPassword"], action.payload.username);
    yield put({ type: actionTypes.AUTH_FORGOT_PASSWORD_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.AUTH_FORGOT_PASSWORD_FAILED, error });
  }
}

function* resetPasswordAction(action: ReturnType<typeof resetPassword>) {
  yield put({ type: actionTypes.AUTH_RESET_PASSWORD_REQUESTED });
  try {
    yield call(
      [Auth, "forgotPasswordSubmit"],
      action.payload.username,
      action.payload.code,
      action.payload.password
    );
    yield put({ type: actionTypes.AUTH_RESET_PASSWORD_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.AUTH_RESET_PASSWORD_FAILED, error });
  }
}

function* verifyEmailAction(action: ReturnType<typeof verifyEmail>) {
  yield put({ type: actionTypes.AUTH_VERIFY_EMAIL_REQUESTED });
  try {
    yield call(
      [Auth, "confirmSignUp"],
      action.payload.username,
      action.payload.code
    );
    yield put({ type: actionTypes.AUTH_VERIFY_EMAIL_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.AUTH_VERIFY_EMAIL_FAILED, error });
  }
}

function* resendVerifyEmailAction(
  action: ReturnType<typeof resendVerifyEmail>
) {
  yield put({ type: actionTypes.AUTH_RESEND_VERIFY_EMAIL_REQUESTED });
  try {
    yield call([Auth, "resendSignUp"], action.payload.username);
    yield put({ type: actionTypes.AUTH_RESEND_VERIFY_EMAIL_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.AUTH_RESEND_VERIFY_EMAIL_FAILED, error });
  }
}

function* getUserSubscription() {
  yield put({ type: actionTypes.AUTH_GET_USER_SUBSCRIPTION_REQUESTED });
  try {
    const state: RootReducer = yield select((state: RootReducer) => state);
    let showAds = false;

    if (state.user.signedIn) {
      const subscription: Generator<any, any> = yield privateQuery(
        userSubscription,
        {},
        CACHE_TIME.MINUTE * 10,
      );
      const premium = (subscription as any).data.userSubscription;
      showAds = !(premium.validTo && premium.validFrom);

      yield put({
        type: actionTypes.AUTH_GET_USER_SUBSCRIPTION_SUCCESS,
        premium: premium,
      });
    }

    unloadAdScript();
  } catch (error) {
    yield put({ type: actionTypes.AUTH_GET_USER_SUBSCRIPTION_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.AUTH_SIGN_IN, signInAction),
    takeLatest(actionTypes.AUTH_SIGN_OUT, signOutAction),
    takeLatest(actionTypes.AUTH_SIGN_UP, signUpAction),
    takeLatest(actionTypes.AUTH_GET_SIGN_IN_USER, getSignInUserAction),
    takeLatest(actionTypes.AUTH_FORGOT_PASSWORD, forgotPasswordAction),
    takeLatest(actionTypes.AUTH_RESET_PASSWORD, resetPasswordAction),
    takeLatest(actionTypes.AUTH_VERIFY_EMAIL, verifyEmailAction),
    takeLatest(actionTypes.AUTH_RESEND_VERIFY_EMAIL, resendVerifyEmailAction),
    takeLatest(actionTypes.AUTH_GET_USER_SUBSCRIPTION, getUserSubscription),
  ]);
}
