import { Switch } from "react-router-dom";
import { DashboardRoute } from "./Router";
import Layout from "@twire/common/Layout";
import Modal from "@twire/common/Modal";
// import SubToPremium from "@twire/common/SubToPremium";
import { getFullRoute, mergeClasses } from "@twire/utility/helpers";
import { URLS } from "@twire/utility/constants";

import Tournaments from "./Tournaments";
import Scrims from "./Scrims";
import Ranking from "./Ranking";
import News from "./News";
import Search from "./Search";
import Plans from "./Plans";
import Settings from "./Settings";

import Home from "./Home";
import Login from "../Login";
import Register from "../Register";
import ForgotPassword from "../ForgotPassword";
import Verification from "../Verification";
import ResetPassword from "../ResetPassword";
import Banner from "../../common/Banner";
import classes from "./Dashboard.module.scss";
import Team from "./Team";
import { AdsContext } from "./context";

const Dashboard: React.FC<{
  onAdClick: () => void;
  onAdLoad: () => void;
}> = () => (
  <Layout>
    <Modal />
    {/* <SubToPremium /> */}
    {/* <Dialog /> */}
    {/* {
      <AdsContext.Consumer>
        {({ showAds }) =>
          showAds && (
            <div className={classes.ads}>
              <div className="iframe-center" data-onpage="true" data-adpath="/339474670,22606297850/Twire/ATF"></div>
            </div>
          )
        }
        {({ showAds }) => (
          showAds && <div className={mergeClasses(
            classes.ads, 'g4a_banner_dxracer'
          )}>
            {
              <div
                className={mergeClasses(
                  classes.banner,
                )}
              >
                <a
                  href="http://dxr.us/Twire"
                  target="_blank"
                  rel="noreferrer"
                  g4a-action={'dxracer_click'}
                  g4a-element-id={'dxracer_banner'}
                  g4a-impression={'dxracer_impression'}
                >
                  {
                    window.innerWidth > 1299 ?
                      <img
                        src="https://twire-assets.s3.eu-west-1.amazonaws.com/ads/dxracer/banner-850.jpg"
                        className={mergeClasses(
                          classes.desktopBanner,
                        )}
                      />
                      :
                      window.innerWidth > 914 ?
                        <img
                          src="https://twire-assets.s3.eu-west-1.amazonaws.com/ads/dxracer/banner-750.jpg"
                          className={mergeClasses(
                            classes.desktopBanner,
                          )}                          />
                        :
                        <img
                          src="https://twire-assets.s3.eu-west-1.amazonaws.com/ads/dxracer/banner-480.jpg"
                          className={mergeClasses(
                            classes.mobileBanner,
                          )}
                        />
                  }
                </a>
              </div>
            }
          </div>
        )}
      </AdsContext.Consumer>
    } */}
    <Banner className={classes.banner} />
    <Switch>
      <DashboardRoute
        component={Tournaments}
        path={getFullRoute(URLS.TOURNAMENTS)}
      />
      <DashboardRoute component={Team} path={getFullRoute(URLS.TEAM)} />
      <DashboardRoute component={Scrims} path={getFullRoute(URLS.SCRIMS)} />
      <DashboardRoute component={Ranking} path={getFullRoute(URLS.RANKING)} />
      <DashboardRoute component={Search} path={getFullRoute(URLS.SEARCH)} />
      <DashboardRoute component={News} path={getFullRoute(URLS.NEWS)} />
      <DashboardRoute component={Login} path={getFullRoute(URLS.LOGIN)} />
      <DashboardRoute component={Register} path={getFullRoute(URLS.REGISTER)} />
      <DashboardRoute
        component={ForgotPassword}
        path={getFullRoute(URLS.FORGOT_PASSWORD)}
      />
      <DashboardRoute
        component={ResetPassword}
        path={getFullRoute(URLS.RESET_PASSWORD)}
      />
      <DashboardRoute
        component={Verification}
        path={getFullRoute(URLS.VERIFICATION)}
      />
      <DashboardRoute component={Settings} path={getFullRoute(URLS.SETTINGS)} />
      <DashboardRoute component={Plans} path={getFullRoute(URLS.PLANS)} />
      <DashboardRoute component={Home} path={getFullRoute("")} exact />
    </Switch>
  </Layout>
);

export default Dashboard;
